import {bindable, inject} from 'aurelia-framework';
import {ModalBodyMap} from "./modal-body-map";
import {DialogService} from "aurelia-dialog";
import * as Fhir from "../classes/FhirModules/Fhir";
import {translations} from "../classes/translations";
import {fhirEnums} from "../classes/fhir-enums";
import {BodymapService} from "../services/BodymapService";

const moment = require("moment");
import {I18N} from "aurelia-i18n";
import * as environment from "../../../config/environment.json";

@inject(DialogService, BodymapService, I18N)
export class PatientCurveBodyMap {
    @bindable patient;

    dialogService: DialogService;

    bodymapService: BodymapService;

    i18n: I18N;

    shown: boolean = false;

    isLoaded = false;

    points = {};

    data = {};

    timeframes;

    constructor(dialogService, bodymapService, i18n: I18N) {
        this.dialogService = dialogService;
        this.bodymapService = bodymapService;
        this.i18n = i18n;
    }

    async attached() {
        await this.createData();
    }

    async createData() {
        const wounds = await this.bodymapService.getWounds();

        wounds.forEach((wound) => {
            this.points[wound.name] = {
                num: 0,
                noSubtype: !wound.hasOwnProperty('subtypes')
            };
            this.data[wound.name] = [];
        });

        this.shown = true;
    }

    patientChanged(patient) {
        Fhir.Rest.Fetch(`Observation?encounter=${patient.encounterId}`).then((result: any[]) => {
            return result.filter((item) => item.resourceType === fhirEnums.ResourceType.observation);
        }).then((result: any[]) => {
            return result.filter((item) => item.code && item.code.text === 'body-map');
        }).then((result: any[]) => {
            result.forEach((item) => {
                const data: any = {
                    id: item.id,
                    identifier: Array.isArray(item.identifier) && item.identifier.length > 0 ? item.identifier[0].value : 1,
                    status: item.status,
                    currentDate: new Date(item.issued),
                    type1: item.category[0].text,
                    info: item.comment || '',
                    date: item.effectiveDateTime || (item.effectivePeriod ? item.effectivePeriod.start : (this.patient.encounter.period ? moment(this.patient.encounter.period.start).toDate() : new Date())),
                    timeType: 'time-point'
                };

                if (item.effectivePeriod && item.effectivePeriod.extension) {
                    const timeFrame = item.effectivePeriod.extension.find((ext) => ext.url === environment.nursItStructureDefinition + 'timeframe-days');

                    if (timeFrame) {
                        data.timeType = 'time-frame';
                        data.timeframe = timeFrame.valueInteger;
                    }
                }

                if (item.bodySite.coding) {
                    data.location = item.bodySite.coding[0].display;

                    if (data.location) {
                        data.locationLocalized = translations.translate(data.location);
                    }
                }

                if (item.component) {
                    data.type2 = item.component[0].code.text;

                    if (item.component[1]) {
                        data.type3 = item.component[1].code.text;
                    }
                }

                this.data[data.type1].push(data);
            });

            Object.keys(this.data).forEach((key) => {
                if (!Array.isArray(this.data[key])) return;

                this.data[key].sort((a, b) => {
                    return a.identifier - b.identifier;
                });
            });

            this.countPoints();

            this.isLoaded = true;
        });
    }

    countPoints() {
        Object.keys(this.points).forEach((point) => {
            this.points[point].num = 0;
        });

        Object.keys(this.data).forEach((key) => {
            if (!Array.isArray(this.data[key])) return;

            this.data[key].forEach((item) => {
                const pointData = this.points[item.type1];

                pointData.num = Math.max(pointData.num, item.identifier);
            });
        });
    }

    toggleExpand(node) {
        this.shown = !this.shown;

        if (!this.shown) {
            node.classList.add('collapsed');
        } else {
            node.classList.remove('collapsed');
        }
    }

    addMedication() {
        this.dialogService.open({
            viewModel: ModalBodyMap, model: {
                patient: this.patient
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                this.data = result.output;

                Object.keys(this.data).forEach((key) => {
                    this.data[key] = this.data[key].filter((item) => !item.deleted);
                });

                this.countPoints();
            }
        });
    }
}

