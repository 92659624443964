import {bindable, inject} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {I18N} from "aurelia-i18n";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {ConfigService} from "../services/ConfigService";

@inject(DialogController, DialogService, I18N)
export class Prompt {
    answer: any;
    @bindable message: string;
    @bindable title: string;
    @bindable noText: string;
    @bindable yesText: string;
    @bindable showNo: boolean;
    @bindable showOther : boolean;
    @bindable otherText : string;
    controller: DialogController;
    service: DialogService;
    yesButtonClass: string = 'btn-default';
    noButtonClass: string = 'btn-default';
    otherButtonClass : string = 'btn-default';

    headerClass: string = '';
    headerCorrospondingClass: string = '';
    showCancel: boolean = true;
    i18n: I18N;

    constructor(controller, service, i18n, taskQueue) {
        this.controller = controller;
        this.answer = null;
        this.service = service;
        this.i18n = i18n;

        this.controller.settings.centerHorizontalOnly = true;
    }

    attached() {
        if (RuntimeInfo.IsIE) {
            try {
                let testDiv = document.createElement("div");
                testDiv.innerHTML = this.message;
                testDiv.style.opacity = "0";
                testDiv.style.position = "absolute";
                testDiv.style.top = "0px";
                testDiv.style.left = "0px";
                document.body.appendChild(testDiv);

                let w = testDiv.clientWidth;
                document.body.removeChild(testDiv);

                let dlg: HTMLDivElement = document.querySelector(".ux-dialog");
                if (dlg) {
                    dlg.style.width = (w + 32 + 16) + "px";
                }
            } catch (e) {
                if (ConfigService.Debug) {
                    console.warn("in Prompt.attached:", e);
                }
            }
        }
    }

    activate(options: IPromptOptions) {
        this.message = options.message;
        this.title = options.title || this.i18n.tr("information");
        this.yesText = options.yesText || this.i18n.tr("yes");
        this.noText = options.noText || this.i18n.tr("no");
        this.showOther = options.showOther;
        this.otherText = options.otherText;

        if (['warning', 'danger', 'success', 'primary', 'info'].indexOf(options.headerClass) > -1) {
            this.headerClass = 'bg-' + options.headerClass;
            this.headerCorrospondingClass = 'text-' + options.headerClass;
        }
        /* if (['warning', 'danger'].indexOf(options.headerClass) > -1) {
            this.yesButtonClass = 'btn-default';
        } */

        if (typeof options.yesButtonClass === "string")
            this.yesButtonClass += ' ' + options.yesButtonClass;

        if (typeof options.noButtonClass === "string")
            this.noButtonClass += ' ' + options.noButtonClass;

        if (typeof options.otherButtonClass === "string")
            this.otherButtonClass += ' ' + options.otherButtonClass;

        if (typeof options.showOther === "undefined") {
            options.showOther = false;
        }

        if (typeof options.showNo === "undefined") {
            options.showNo = true;
        }

        if (typeof options.showCancel === "undefined") {
            options.showCancel = true;
        }

        this.showCancel = options.showCancel;
        this.showNo = options.showNo;
    }
}

export interface IPromptOptions {
    message: string;
    title: string;
    yesText: string;
    noText: string;
    showNo?: boolean;
    showOther? : boolean;
    otherText? : string;
    yesButtonClass?: string;
    noButtonClass?: string;
    otherButtonClass? : string;
    value?: any;
    headerClass?: string;
    showCancel?: boolean;
    inputType?: string;
}
