import { translations } from "../../classes/translations";
import { bindable } from "aurelia-framework";
import { Router } from "aurelia-router";
import { PatientItem } from "../../classes/Patient/PatientItem";
import * as Fhir from "../../classes/FhirModules/Fhir";
import { PatientChangeNotifier } from "resources/services/PatientChangeNotifier";
import { IQuestionnaireList, QuestionnaireService } from "resources/services/QuestionnaireService";
import { PatientService } from "resources/services/PatientService";
import { fhirEnums } from "../../classes/fhir-enums";
import { I18N } from "aurelia-i18n";
import { ConfigService } from "../../services/ConfigService";
import { RuntimeInfo } from "../../classes/RuntimeInfo";
import { PatientDischarge } from "../../../views/patient/discharge";
import { DialogService } from "aurelia-dialog";
import { QuestionnaireDialogContainer } from "../questionnaire/questionnaire-dialog-container";
import { FhirService } from "resources/services/FhirService";
import { autoinject } from "aurelia-dependency-injection";

/*
for a quick removal of the all Discharge Responses do a:
> for (const r of QuestionnaireService.GetResponsesOfType(PatientItem.LastLoadedPatient, QuestionnaireService.GetQuestionnaireDirect("CareITDischargeManagementsempa").id)) { 
    await Fhir.Rest.Delete(r);
    PatientItem.LastLoadedPatient.questionnaireResponses = PatientItem.LastLoadedPatient.questionnaireResponses.filter(o=>o.id !== r.id);
  }
*/

@autoinject
export class patientReleaseManagement {
    formId: string = "discharge_form";
    response = undefined;
    hasFormChanges = false;
    @bindable patient: PatientItem;

    constructor(protected router: Router, 
        protected notifier: PatientChangeNotifier, 
        protected patientService: PatientService, 
        protected i18n: I18N, 
        protected questionnaireService: QuestionnaireService,
        protected dialogService: DialogService
    ) {
        if (ConfigService.Debug) window["releaseMgt"] = this;
        if (ConfigService && ConfigService.cfg && ConfigService.cfg["recare"] && typeof ConfigService.cfg["recare"]["enabled"] === "boolean") {
            this.configRecareEnabled = ConfigService.cfg["recare"]["enabled"];
            PatientDischarge.Recare.enabled = this.configRecareEnabled;
        }
    }

    async patientChanged() {
        let config = ConfigService.GetFormSettings("discharge");
        if (config?.enabled === false) return;
        if (this.patient) {
            await ConfigService.LoadConfigOverride(this.patient.ward, this.patient);
            QuestionnaireService.GetQuestionnaireIds()
                .then((result: IQuestionnaireList) => {
                    this.patientService.ensureQuestionnaireExistance(this.patient, result.QDischargeManagementId)
                        .catch((error) => {
                            console.warn(error.message || JSON.stringify(error));
                        });
                });
        }

        this.ensureRecareUrl();
    }

    ensureRecareUrl() {
        //    if (ConfigService.Debug) console.debug("[Patient-Release-Management] - ensuring recare is read correct");

        if (!this.patient || this.patient.recareUrl) return;

        if (this.patient.selectedAdditionalInfo) {
            let recareItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.selectedAdditionalInfo, "patient_profile_link", false);
            if (recareItem) {
                let tmpUrl = Fhir.QuestionnaireResponse.GetResponseItemValue(recareItem);
                if (tmpUrl && tmpUrl !== "") {
                    this.patient.recareUrl = tmpUrl;
                }
            }
        }
    }

    configRecareEnabled: boolean = false;

    get hasRecare(): boolean {
        if (!this.patient) return false;
        if (this.configRecareEnabled && !this.patient.recareUrl)
            this.ensureRecareUrl();

        return this.configRecareEnabled && !!this.patient.recareUrl;
    }

    openInRecare() {
        if (!this.patient.recareUrl || this.patient.recareUrl === "") return;
        window.open(this.patient.recareUrl, "_recare", "toolbar=no, statusbar=no");
    }

    async openDischargeForm() {
        if (!this.dialogService) {
            console.warn('DialogService does not exist!');
            return;
        }

        const settings = ConfigService.GetFormSettings('discharge', this.patient);
        if (!settings) {
            console.warn('No settings for route "discharge" found');
            return;
        }

        let questionnaireName = settings.questionnaireName;
        if (!questionnaireName) {
            questionnaireName = 'CareITDischargeManagementSempa';
            console.warn(`No questionnaireName in route "discharge" specified falling back to "${questionnaireName}"`);
        }

        const questionnaire = QuestionnaireService.GetQuestionnaireDirect(questionnaireName);
        if (!questionnaire) {
            console.warn(`Questionnaire with name "${questionnaireName}" as specified in route "discharge" not found!`);
            return;
        }

        RuntimeInfo.IsLoading = true;
        let response: any;
        try {
            response = QuestionnaireService.GetLatestResponseOfName(this.patient, questionnaireName, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.inProgress]);
        } catch (e) {
            console.warn(e);
        }

        if (!response && questionnaire) {
            if (ConfigService.Debug)
                console.debug(`No Response for "${questionnaire.name}" found. Creating a blank one`);

            response = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, questionnaire.id, fhirEnums.QuestionnaireResponseStatus.completed);
            await Fhir.Rest.Update(response);

            PatientService.AddQuestionnaireResponse(this.patient, response, true);
        }

        this.router.navigateToRoute('discharge', { responseId: response.id});

        return;
        const enc = this.patient.encounter;
        const dialogSettings = {
            grouplist: false,
            response: response,
            questionnaire: questionnaire,
            encounter: enc,
            tooold: false,
            haschanges: false,
            dialogService: this.dialogService,
            status: this.i18n.tr(response?.status === fhirEnums.QuestionnaireResponseStatus.inProgress ? 'in-progress' : 'validated'),
            saveText: this.i18n.tr("finalize"),
            abortText: this.i18n.tr("abort"),
            patientService: PatientService,
            patient: this.patient,
            allowAddNew: false,
            showSelection: false,
            showToolbar: false,
            removeNoToolbarWindow: false,
            forcedReadonly: false,
            advancedSaveButton: true,
            needsEditButton: true,
            data: {
                title: questionnaire.title || questionnaire.name
            }
        };

        return this.dialogService
            .open({ viewModel: QuestionnaireDialogContainer, model: dialogSettings, lock: true })
            .whenClosed(async result => {
                // BasicForm.pageTitle = currentTitle;
                if (!result.wasCancelled) {  // user clicked on "Validate"
                    RuntimeInfo.IsLoading = true;
                    PatientItem.UpdatePatientDischargePct(PatientItem.SelectedPatient, this.i18n);

                    const response = result.output;
                    if (!response.meta) {
                        response.meta = {};
                    }

                    if (!response.meta.lastUpdated) {
                        response.meta.lastUpdated = new Date().toJSON();
                    }

                    if (!response.questionnaire) {
                        if (FhirService.FhirVersion >= 4) {
                            response.questionnaire = `${QuestionnaireService.NitQuestionnairesUrl}/${questionnaire.name}`;
                        } else {
                            response.questionnaire = {
                                reference: `Questionnaire/${questionnaire.id}`
                            };
                        }
                    }

                    try {
                        // this.patientChangeNotifier.notify(patient, patient.flags);

                        await Fhir.Rest.Update(response);
                        PatientService.AddQuestionnaireResponse(this.patient, response, true);
                    } finally {
                        RuntimeInfo.IsLoading = false;
                    }
                }
            })
    }

    get redReasons(): string[] {
        return this.patient && this.patient.releaseInformation && this.patient.releaseInformation.redReasons ? this.patient.releaseInformation.redReasons : [];
    }

    get pctInt() {
        if (!this.patient || typeof this.patient.dischargePercent === "undefined") return NaN;
        return parseInt(String(this.patient.dischargePercent));
    }

    get pctDone() {
        let p = this.pctInt;
        return isNaN(p) ? "0" : p + "%";
    }

    get pctDoneText() {
        if (this.patient) {
            if (typeof this.patient.dischargePercent === "undefined") {
                return translations.translate("no_active_discharge_plan");
            } else {
                return `${this.patient.dischargePercent}%`;
            }
        }

        return "?";
    }

    get color() {
        return this.patient ? this.patient.dischargeColor : "transparent";
    }

    tr(stringId) {
        return translations.translate(stringId);
    }

    attached() {
        if (!this.patient) this.patient = PatientService.LatestPatient;
        if (this.patient && !isNaN(this.patient.dischargePercent) && (!this.patient.releaseInformation.redReasons || this.patient.releaseInformation.redReasons.length === 0)) {
            PatientItem.UpdatePatientDischargePct(PatientItem.SelectedPatient, this.i18n);
        }
    }
}
