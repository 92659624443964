import { PatientItem } from "resources/classes/Patient/PatientItem";
import { IPprAgeSetting } from "./IPprAgeSetting";
import { I18N } from "aurelia-i18n";
import { IFormSetting } from "resources/classes/IFormSettings";
import { ConfigService } from "resources/services/ConfigService";
import { QuestionnaireService } from "resources/services/QuestionnaireService";
import { QuestionnaireResponse } from "resources/classes/FhirModules/QuestionnaireResponse";
import { NitTools } from "resources/classes/NursitTools";
import { fhirEnums } from "resources/classes/fhir-enums";
import { RuntimeInfo } from "resources/classes/RuntimeInfo";
import { FhirService } from "resources/services/FhirService";

const moment = require("moment");

export class PprInfo {
    cfg? : IFormSetting;
    message?: string;
    iconClass: string;
    iconColor : string;
    isValid?: boolean;
    isEnabled : boolean = true;
    questionnaireTitle?: string;
    questionnaireName? : string;
    responseDate?: string;
    responseState?: string;
    hasResponse? : boolean;
    
    text1Content?: string;
    text2Content?: string;
    text3Content?: string;
    text4Content?: string;

    text1Class?: string;
    text2Class?: string;
    text3Class?: string;
    text4Class?: string;

    isoEnabled? : boolean = true;
    report?: string;
    
    constructor(patient : PatientItem, i18n : I18N) {
        this.cfg = ConfigService.GetFormSettings('PPR2.0');
        this.questionnaireName = this.cfg?.questionnaireName || 'CareITPPR20E';

        if (this.cfg?.enabled === true) {
            this.isEnabled = true;
        } else {
            this.isEnabled = false;
        }

        if (patient && this.isEnabled) {
            this.patientChanged(patient, i18n);
        }
    }

    public static async Load(patient, i18n : I18N) : Promise<PprInfo> {
        try {
            const result = new PprInfo(undefined, i18n);
            await (result.patientChanged(patient, i18n));

            return Promise.resolve(result);
        }
        catch(ex) {
            return Promise.reject(ex)
        }
    }


    async patientChanged(patient : PatientItem, i18n : I18N) {
        let pprAgeSetting  = undefined;
        if (!patient || !this.isEnabled) {
            return;
        }

        // planio #34743, select ppr questionnaire by age of patient
        // use the default values provided in the route config as default
        pprAgeSetting = {
            $_INFO: "Default route values for PPR from route config",
            questionnaireName : this.cfg.questionnaireName,
            isoEnabled: true,
            from: -1,
            to: -1
        }

        // check if there are valid age ranges configured
        if (!this.cfg?.settings?.ageRanges && this.isEnabled) {
            console.warn('PPR2.0: no settings.ageRange detected in config. Please specify them in route "PPR2.0" -> settings -> ageRanges[]!');
            alert("PPR is enabled but not correctly configured.\nPlease let the support check your config file.");
        }

        // always remind to have a valid birthdate in the patient
        // find correct age range (and assume the ranges are configured correctly..)
        if (patient.birthDate) {
            const bd = moment(new Date(patient.birthDate));
            const days = moment(new Date()).diff(bd, 'days', false);
            for (const setting of <IPprAgeSetting[]>this.cfg.settings.ageRanges) {
                if (typeof setting.from != "number" || typeof setting.to != "number" || !setting.questionnaireName) {
                    console.warn('PPR2.0: invalid age range specified in: ', setting);
                    continue;
                }

                if (days > setting.from && days <= setting.to) {
                    pprAgeSetting = NitTools.Clone(setting);
                    break;
                }
            }
        }

        // overwrite the questionnaire name from setting. This is the important step all the stuff above point to
        this.isoEnabled = pprAgeSetting.isoEnabled;
        this.questionnaireName = pprAgeSetting.questionnaireName;

        const questionnaire = QuestionnaireService.GetQuestionnaireDirect(pprAgeSetting.questionnaireName);
        this.questionnaireTitle = questionnaire?.title ?? 'PPR 2.0';
        
        if (!NitTools.IsArray(patient.questionnaireResponses)) patient.questionnaireResponses = [];

        if (patient.questionnaireResponses.length === 0 && patient.encounterId) {
            // here we are, but the patient has no questionnaires. So lets load them from fhir
            
            // patient = await PatientItem.Load(patient.encounterId);
            //console.warn("Loading PPR Responses! for encounter: " + patient.encounterId);
            const fs = new FhirService();
            // const url = `QuestionnaireResponse?encounter=${patient.encounterId}&questionnaire=${encodeURIComponent(questionnaire.url)}`;
            const url = `QuestionnaireResponse?encounter=${patient.encounterId}&questionnaire=${questionnaire.url}&status=completed,amended,in-progress&_sort=-authored&_count=1`;
            
            const responses = await fs.fetch(url, true, undefined, false);
            patient.questionnaireResponses.push(...responses);
        }

        // now get the values from the latest response:
        this.text3Class = this.text1Class = 'grey';
        this.text1Content = this.text2Content = this.text3Content = this.text4Content = '?';

        const response = QuestionnaireService.GetLatestResponseOfName(patient, this.questionnaireName, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.inProgress]);
        /* if (!response && ConfigService.Debug) {
            console.log(`PPR2.0: No Response for ${this.questionnaireName}`);
        } */
        
        this.responseChanged(response, i18n);
        const valNIT_PPREB_301 = QuestionnaireResponse.GetResponseItemValueByLinkId(response, "NIT_PPREB_301", "?");
        const valNIT_PPREB_303 = QuestionnaireResponse.GetResponseItemValueByLinkId(response, "NIT_PPREB_303", "?");
        const valNIT_PPREB_305 = QuestionnaireResponse.GetResponseItemValueByLinkId(response, "NIT_PPREB_305", "?");
        const valNIT_PPREB_399 = QuestionnaireResponse.GetResponseItemValueByLinkId(response, "NIT_PPREB_399", "?");

        // NIT_PPREB_306 Isolation
        const rNIT_PPREB_306 = QuestionnaireResponse.GetResponseItemByLinkId(response, "NIT_PPREB_306");        
        const valNIT_PPREB_306 = QuestionnaireResponse.GetResponseItemValue(rNIT_PPREB_306, "?")
        let dspNIT_PPREB_306 = '?';

        // handle the isolation answer
        switch (valNIT_PPREB_306) {
            case 'NIT_PPREB_306_90': // yes
                dspNIT_PPREB_306 = i18n.tr('yes');
                this.text3Class = 'green';
                break;
            case 'NIT_PPREB_306_00': // no
                dspNIT_PPREB_306 = i18n.tr('no');
                this.text3Class = 'grey';
                break;
            default: // per default, when someone changed the answers, use default from the answer.display
                dspNIT_PPREB_306 = QuestionnaireResponse.GetResponseItemDisplay(rNIT_PPREB_306, "?")
                this.text3Class = dspNIT_PPREB_306 == '?' ? 'orange' : '';
                break;
        }

        this.text1Content = `${valNIT_PPREB_301}/${valNIT_PPREB_303}`;        
        this.text2Content = `${valNIT_PPREB_305} min`;
        this.text3Content = dspNIT_PPREB_306;
        this.text4Content = `${valNIT_PPREB_399} min`;

        this.text1Class = this.text1Content.indexOf('?') > -1 ? 'orange' : 'green';    // when one of the two values is '?'
        this.text2Class = String(valNIT_PPREB_305) === '0' || valNIT_PPREB_305==='?' ? 'orange' : 'green'; // when value is 0 or '?'
        // this.classText3 is set by the switch statement
        this.text4Class = String(valNIT_PPREB_399) === '0' || valNIT_PPREB_399==='?' ? 'orange' : 'green'; // when value is 0 or '?'
    }

    async responseChanged(value, i18n : I18N) {
        // default to worst case
        this.iconColor = 'red';
        this.isValid = false;
        this.message = i18n.tr("ppr_no_document", { questionnaireTitle: this.questionnaireTitle })  //  `Kein ${this.questionnaireTitle} Dokument vorhanden!`;
        this.responseDate = '';
        this.hasResponse = !!value;
        this.iconClass = this.hasResponse ? 'mdi-warning-amber' : 'mdi-notification-important'; 
        this.responseState = undefined;

        if (!value) {
            return;
        }

        this.responseState = value.status;
         // Planio #3462 - get the last saved date time of the response and set a status indicator
         if (value?.authored) {
            const m = moment(value.authored);
            const mObj = m.toObject();
            const today = moment(new Date()).toObject();
            this.responseDate = m.format(RuntimeInfo.DateTimeFormat);
            // is the latest response from today?            
            if (mObj.date === today.date && mObj.month === today.month && mObj.year === today.year) {
                // take a look at the response status
                if (['completed', 'amended'].indexOf(value.status) > -1) {
                    // everything is fine, so green
                    this.iconColor = 'green'; // '#b9fdaf';
                    this.iconClass = 'mdi-check';
                    this.message = i18n.tr('ppr_ok', { status: i18n.tr(value.status), questionnaireTitle: this.questionnaireTitle });
                    this.isValid = true;
                } else {
                    if (value.status === 'in-progress') {
                        // not finalized, so yellow
                        this.iconColor = 'orange'; // '#faa62d';
                        this.message = i18n.tr('ppr_not_finalized', { questionnaireTitle: this.questionnaireTitle});
                    } else {
                        // something other, so red
                        this.message = `Status: ${value.status}`;
                    }
                }
            } else {
                // not from today, so the status is red
                this.message = i18n.tr("ppr_not_today", {questionnaireTitle : this.questionnaireTitle, previousDate: this.responseDate});
            }
        }
    }
}
