import {ConfigService} from "../../services/ConfigService";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {bindable} from "aurelia-templating";
import {UserService} from "../../services/UserService";
import {FhirService} from "../../services/FhirService";
import {autoinject} from "aurelia-framework";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {fhirEnums} from "../../classes/fhir-enums";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {SubdelegationInfo} from "../../../views/patient/subdelegation";
import {Router} from "aurelia-router";
import {PatientService} from "../../services/PatientService";
import {IQuestionnaireDialogSettings} from "../questionnaire/questionnaire-dialog-container";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {Tools} from "../../classes/FhirModules/Fhir";
import {IFormSetting} from "../../classes/IFormSettings";
import {ModalPatientSubdelegationAdd} from "./modal-patient-subdelegation-add";
import {PatientSubdelegationPopup} from "./patient-subdelegation-popup";
import {ReportService} from "../../services/ReportService";

const moment = require('moment');

@autoinject
export class PatientSubdelegation {
    showAddButton: boolean = false;
    items: ISubdelegationInfoItem[] = [];
    public static Practitioners: any[] = [];

    protected get practitioners() {
        return PatientSubdelegation.Practitioners;
    }

    @bindable patient: PatientItem;

    get patientHasSubdelegations(): boolean { // this is not correct!
        return this.items && this.items.length > 0;
    }

    constructor(protected i18n: I18N, protected fhirService: FhirService, protected router: Router, protected dialogService: DialogService, protected patientService: PatientService) {
    }

    cfg: IFormSetting;
    questionnaire: any;

    async patientChanged(p) {
        this.items = [];
        if (!p) return;

        PatientSubdelegation.Practitioners = [];

        await ConfigService.LoadConfigOverride(p?.ward);
        this.cfg = ConfigService.GetFormSettings('subdelegation');
        this.showAddButton = UserService.UserHasQualification(['DGKP', 'SSL', 'PPL']);

        if (this.cfg && this.cfg.enabled && this.cfg.questionnaireName) {
            this.questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(this.cfg.questionnaireName);
            if (!this.questionnaire) return;
            const responses = QuestionnaireService.GetResponsesOfType(p, this.questionnaire.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
            for (const response of responses) {
                this.addItemFromInfo(new SubdelegationInfo(response, this.questionnaire));
            }
        }
    }

    private addItemFromInfo(info: SubdelegationInfo): ISubdelegationInfoItem {
        const item = {
            affectedGroups: info.affectedGroups,
            forUserDisplay: info.forUserDisplay,
            forUserId: info.forUserId,
            forUserQualification: info.forUserQualification,
            isExpired: info.isExpired,
            responseId: info.responseId,
            isValid: info.isValid,
            forPractitionerId: info.forPractitionerId
        };

        this.items.push(item);

        return item;
    }

    printProgress() {
        if (this.items && this.items[0]) {
            const report = (this.cfg?.report?.name || this.questionnaire.name).toUpperCase().replace('.FRX', '').trim()
                + this.cfg?.report?.progressSuffix;

            ReportService.Preview(this.items[0].responseId, report, undefined, false);
        }
    }

    /***
     * Display the popup form containing the Subdelegation Questionnaire
     * @param item
     * @param afterClose
     */
    private async showSubdelegationPopupForm(item: ISubdelegationInfoItem, afterClose?: Function) {
        // get the response from fhir direct to always read the latest versionId. That is important/needed for the version select in the popup
        const questionnaireResponse = <any>await this.fhirService.get(`QuestionnaireResponse/${item.responseId}`);
        PatientService.AddQuestionnaireResponse(this.patient, questionnaireResponse, true);

        const enc = this.patient.encounter;
        const dialogSettings: IQuestionnaireDialogSettings = {
            grouplist: false,
            response: questionnaireResponse,
            questionnaire: this.questionnaire,
            encounter: enc,
            tooold: false,
            haschanges: false,
            dialogService: this.dialogService,
            // status: item.isExpired ? '<b style="color: red">Abgelaufen</b>' : '<b style="color: lightgreen">Aktiv</b>',
            saveText: this.i18n.tr('save'), // is overwritten in the SD Popup
            abortText: this.i18n.tr("abort"),
            patientService: this.patientService,
            allowAddNew: false,
            showSelection: false,
            showToolbar: false,
            removeNoToolbarWindow: false,
            forcedReadonly: !UserService.UserHasQualification(['SSL', 'PPL', 'DGKP']),
            data: {
                title: this.questionnaire.title || this.questionnaire.name,
                item: item,
                practitioner: this.practitioners
            }
        };

        return this.dialogService
            .open({viewModel: PatientSubdelegationPopup, model: dialogSettings, lock: true})
            .whenClosed(async result => {
                // BasicForm.pageTitle = currentTitle;
                if (!result.wasCancelled) {  // user clicked on "Validate"
                    RuntimeInfo.IsLoading = true;

                    if (!result.output && dialogSettings.response) // when no output is provided then set the current response to stopped
                        dialogSettings.response.status = 'stopped';

                    try {
                        const resource: any = result.output ? <any>result.output : dialogSettings.response;

                        if (resource.status === 'in-progress')
                            resource.status = 'completed';
                        else if (resource.status === 'completed')
                            resource.status = 'amended';

                        const info = new SubdelegationInfo(resource, this.questionnaire);
                        const practitioner = this.practitioners.find(o => o.id === info.forPractitionerId);
                        /* I personally do not think that it is right to extend the period every save:
                        if (info.isExpired && UserService) {
                            info.expiration = UserService.PractitionerHasQualification(practitioner, ['PFA']) ? undefined : moment(new Date()).add(90, 'days').toDate()
                        } */

                        info.affectedGroups = SubdelegationInfo.GetAffectedGroups(this.questionnaire, resource).join(',');

                        await this.fhirService.update(resource, false);
                        PatientService.AddQuestionnaireResponse(this.patient, resource, true);

                        if (typeof afterClose === 'function')
                            afterClose(result.output);

                        await this.patientChanged(this.patient);
                    } catch (e) {
                        console.warn(e);
                    } finally {
                        RuntimeInfo.IsLoading = false;
                    }
                }
            })
            .catch(e => {
                console.warn(e);
            });
    }

    async showNewSubdelegationDialogue() {
        this.dialogService.open({
            viewModel: ModalPatientSubdelegationAdd,
            model: {
                patient: this.patient
            }
        })
            .whenClosed(async result => {
                if (result.wasCancelled)
                    return;

                // const selectedPrac = this.practitioners.find(o => o.id === result.output);
                const  selectedPrac = result.output;
                if (!selectedPrac) {
                    console.warn(`No Practitioner returned when there has been expected to be one!`);
                    return;
                }

                const accountId: string = selectedPrac.identifier.find(o => o.system.endsWith('smile-account-id'))?.value;
                const existingItem = this.items.find(o => o.forPractitionerId === selectedPrac.id);

                if (existingItem) {
                    return this.gotoSubDelegation(existingItem);
                } else {
                    const newResponse = Tools.SubstituteDefaultQRSkeleton(this.patient, this.questionnaire.id, 'completed');
                    const info = new SubdelegationInfo(newResponse, this.questionnaire);
                    info.fromUserId = UserService.UserName.toUpperCase();
                    info.fromUserDisplay = `${UserService.UserLastName}, ${UserService.UserFirstName}`;
                    info.expiration = UserService.PractitionerHasQualification(selectedPrac, ['PFA']) ? undefined : moment(new Date()).add(90, 'days').toDate();

                    info.forUserId = accountId;
                    info.forUserDisplay = selectedPrac.name ? [selectedPrac.name.find(o => o.family)?.family, selectedPrac.name.find(o => o.family)?.given?.join(' ')].join(', ') : `Ohne Namen (${accountId || selectedPrac.id})`;
                    info.forUserQualification = UserService.GetPractitionerQualifications(selectedPrac).join(',');
                    info.forPractitionerId = selectedPrac.id;

                    PatientService.AddQuestionnaireResponse(this.patient, newResponse, true);
                    try {
                        await this.fhirService.update(newResponse, false);
                        this.gotoSubDelegation(this.addItemFromInfo(info));
                    } catch (e) {
                        console.warn(e);
                    }
                }
            })
            .catch(e => {
                console.warn(e);
            });
    }

    gotoSubDelegation(item: ISubdelegationInfoItem) {
        this.showSubdelegationPopupForm(item, () => {
            if (ConfigService.Debug) {
                console.debug("SD form closed");
                delete window["sd"];
            }
        })
            .catch(e => {
                console.warn(e);
            });
        // this.router.navigateToRoute('subdelegation', { responseId: item.responseId })
    }

    async attached() {
        if (ConfigService.Debug) window["patientSubdelegation"] = this;

        /**************
         // when no practitioners are loaded, load them and push them to the static field
         *******************/
    }
}

export interface ISubdelegationInfoItem {
    isExpired: boolean;
    forUserDisplay: string;
    forUserId: string;
    forPractitionerId: string;
    forUserQualification: string;
    affectedGroups: string;
    responseId: string;
    isValid: boolean;
}
