import {IPromptOptions, Prompt} from "./prompt";
import {RuntimeInfo} from "../classes/RuntimeInfo";

const moment = require("moment");

export class PromptInput extends Prompt {
    value: any = undefined;
    inputType: string;

    dpOptions = {
        sideBySide: false,
        icons: {
            time: "fa fa-clock-o",
            date: "fa fa-calendar",
            up: "fa fa-arrow-up",
            down: "fa fa-arrow-down",
            close: "fa fa-check",
            today: "fa fa-calendar-times-o"
        },
        locale: this.i18n.i18next.language,
        format: RuntimeInfo.DateTimeFormat,
        toolbarPlacement: 'bottom',
        showClear: true,
        showTodayButton: true,
        showClose: true,
        widgetPositioning: {horizontal: 'left'},
        inline: RuntimeInfo.IsMobile
    };

    activate(options: IPromptOptions) {
        super.activate(options);
        this.value = options.value;
        this.showCancel = false;
        this.inputType = options.inputType || "text";

        if (this.inputType === "date") {
            if (this.isMobile) {
                this.value = moment(this.value).format("YYYY-MM-DDTHH:mm:ss");
            } else {
                this.value = moment(this.value).toDate();
            }
        }
    }

    get isMobile() {
        return RuntimeInfo.IsMobile;
    }
}
